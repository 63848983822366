<template>
  <div class="summary-effiency-wrapper">
    <div
      ref="summaryPriceWrapperRef"
      class="summary-price-wrapper"
      :class="isShowNewFooterLayoutStyle ? 'summary-effiency-wrapper__content' : ''"
    >
      <!-- 价格、税费相关的信息 -->
      <div
        v-if="isShowNewFooterLayoutStyle"
        class="price-tax-new"
        @click="toggleTotal"
      >
        <span
          v-if="!orderConfirmDiscountSwitchByAbt"
          ref="totalTextRef"
          class="price-tax-new__total-text"
        >
          {{ language.SHEIN_KEY_PWA_15097 }}
        </span>
        <div class="price-tax-new__total-content">
          <!-- 价格、税费、icon横向布局 -->
          <div
            v-if="isShowHorizontalLayout"
            class="price-tax-new__horizontal-layout"
          >
            <span
              ref="totalPriceRef"
              class="price-tax-new__total-price"
              :class="{'price-tax-new__color-discount': isDiscountTotalColor}"
            >
              {{ totalPrice }}
            </span>
            <span
              v-if="checkShowTotalFee"
              ref="totalTaxRef"
              class="price-tax-new__total-tax"
            >
              + {{ govTaxPrice }}
            </span>
            <i 
              v-if="showSaveAmount"
              class="suiiconfont price-tax-new__total-icon"
              :class="[isShowPriceDetail ? 'sui_icon_more_down_12px' : 'sui_icon_more_up_12px']"
            ></i>
          </div>
          <!-- 价格、税费、icon的宽度超过一定的宽度的时候，需要换行布局，当文本超级长的时候，需要进行字号的缩放 -->
          <div
            v-else
            class="price-tax-new__scale-layout"
          >
            <div
              v-if="price.grandTotalPrice"
              :class="{'price-tax-new__color-discount': isDiscountTotalColor}"
              class="price-tax-new__total-price"
            >
              <SAdapterText
                min-size="14"
                :text="totalPrice"
              />
            </div>
            <div class="price-tax-new__total-right-content">
              <div
                v-if="checkShowTotalFee"
                class="price-tax-new__total-tax"
              >
                <SAdapterText
                  min-size="10"
                  :text="`+ ${govTaxPrice}`"
                />
              </div>
              <i 
                v-if="showSaveAmount"
                class="suiiconfont price-tax-new__total-icon"
                :class="[isShowPriceDetail ? 'sui_icon_more_down_12px' : 'sui_icon_more_up_12px']"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="price-tax"
        @click="toggleTotal"
      >
        <span
          v-if="!orderConfirmDiscountSwitchByAbt"
          class="price-tax__total-text"
        >
          {{ language.SHEIN_KEY_PWA_15097 }}
        </span>
        <span
          v-if="price.grandTotalPrice"
          class="price-tax__total-price"
          :class="{'price-tax__color-discount': isDiscountTotalColor}"
        >
          {{ price.grandTotalPrice.amountWithSymbol }}
        </span>
        <span
          v-if="checkShowTotalFee"
          class="price-tax__total-tax"
        >
          + {{ govTaxPrice }}
        </span>
        <i 
          v-if="showSaveAmount"
          class="suiiconfont price-tax__total-icon"
          :class="[isShowPriceDetail ? 'sui_icon_more_down_12px' : 'sui_icon_more_up_12px']"
        ></i>
      </div>
      <!-- totalFee相关部分 -->
      <div
        v-if="checkShowTotalFee"
        class="summary-price-wrapper__totalfee"
      >
        ( <span
          v-html="template(`<em style='color: #000;'>${govTaxPrice}</em>`, language.SHEIN_KEY_PWA_18953)"
        ></span> )
        <i
          v-enterkey
          class="suiiconfont sui_icon_doubt_16px_2"
          role="button"
          tabindex="0"
          @click.stop="totalFeeMaskTips"
        ></i>
      </div>
      <!-- 公司税展示 -->
      <template v-if="!isBrOrderInSheinWithMultiTax">
        <OrderSummaryNormalTax
          v-for="tax in companyTaxes"
          :key="`tax_${tax.taxType}`"
          :tax-info="tax"
          :language="language"
          :is-show-ca-gst="isShowCaGST"
          :country-code="orderCountryCode"
          class="tax-price"
          @clickShowGst="clickMaskGst"
        />
      </template>
      <!-- 节约的价钱 -->
      <div
        v-if="savePriceTips"
        class="summary-price-wrapper__save-price"
      >
        {{ savePriceTips }}
      </div>
    </div>
      
    <slot></slot>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { template as _commonTemplate } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import OrderSummaryNormalTax from '../OrderSummaryNormalTax.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'SummaryEffiency',
  components: {
    OrderSummaryNormalTax,
    SAdapterText,
  },
  props: {
    checkShowTotalFee: {
      type: Boolean,
      default: false
    },
    govTaxPrice: {
      type: [Number, String],
      default: 0
    },
    isShowCaGST: {
      type: Boolean,
      default: false
    },
    isPrePay: {  // 卡支付前置
      type: Boolean,
      default: false
    },
    prePayUnPayParams: {
      type: Object,
      default: () => ({})
    },
    // 组件入口来源，用于部分弹窗不展示按钮动效
    source: {
      type: String,
      default: '',
    }
  },
  data(){
    return{
      isClient: false,
      isShowHorizontalLayout: true, // 价钱、税费、icon三部分是否横向布局
      isOpacityChange: false,
    }
  },
  computed: {
    ...mapState('checkout', ['price', 'language', 'checkout', 'checkoutAbtInfo', 'locals', 'isOrderTotalOut', 'isShowPriceDetail', 'status']),
    ...mapGetters('checkout', ['isFreePrice', 'showSaveAmount', 'orderConfirmDiscountSwitchByAbt', 'isShowNewFooterLayoutStyle']),
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    isInstallmentPay() {
      return this.status?.cardPay === 'routepay-cardinstallment'
    },
    totalPrice() {
      const isUseInstallmentTotalPrice = this.isInstallmentPay && Object.keys(this.prePayUnPayParams?.installmentsInfo || {}).length > 0

      return isUseInstallmentTotalPrice ? this.prePayUnPayParams?.installmentsInfo?.total_amount : this.price?.grandTotalPrice?.amountWithSymbol
    },
    savePriceTips() {
      let tip = ''
      if(!this.showSaveAmount) return tip

      if(+this.price?.saved_total_price?.amount > 0 && this.price?.saved_total_price?.amountWithSymbol) tip = this.template(this.price.saved_total_price.amountWithSymbol, this.language.SHEIN_KEY_PWA_23921)
      
      return tip
    },
    // 公司税费
    companyTaxes () {
      const { tax = [] } = this.price || {}
      return tax?.filter(item => item?.taxPrice?.amount > 0 && item?.taxPayType === 1)
    },
    orderCountryCode () {
      return this.checkout?.default_address?.value || ''
    },
    multipleTaxesCountries(){
      return this.locals?.MULTIPLE_TAXES_SPLIT_DISPLAY || gbCommonInfo.MULTIPLE_TAXES_SPLIT_DISPLAY
    },
    isBrOrderInSheinWithMultiTax() {
      const isMultiTaxCountry = this.multipleTaxesCountries?.includes(this.orderCountryCode)
      return !this.IS_RW && isMultiTaxCountry && ['BR'].includes(this.orderCountryCode)
    },
    // 总价色值调整
    isDiscountTotalColor () {
      return this.isFreePrice || !!this.savePriceTips
    }
  },
  watch: {
    govTaxPrice(newVal, oldVal){
      if (newVal !== oldVal) {
        this.handleTotalContentWidth()
      }
    },
    totalPrice(newVal, oldVal){
      console.log('newVal, oldVal >>>', newVal, oldVal)
      if (this.isPrePay) return // 避免在 pre-pay 的场景下底部抖动
      if (newVal !== oldVal) {
        this.handleTotalContentWidth()
      }
    },
    isOrderTotalOut(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleFooterLeftContentStyle(newVal)
      }
    }
  },
  mounted(){
    this.isClient = true
    this.handleTotalContentWidth()
  },

  methods: {
    template: _commonTemplate,
    totalFeeMaskTips() {
      this.$emit('totalFeeMaskTips')
    },
    clickMaskGst() {
      this.$emit('clickMaskGst')
    },
    toggleTotal() {
      if(!this.showSaveAmount) return

      daEventCenter.triggerNotice({
        daId: '1-11-1-114',
      })
      this.$emit('toggleTotal')
    },
    /**
     * @description: 对页面底部统计价钱或者税费的区域，进行部分区域宽度计算处理
     * 如果isClient为false的时候，说明是服务端渲染，服务端渲染是获取不到ref，因此避免报错，服务端渲染依旧走原来的逻辑，进行占位
     */
    handleTotalContentWidth() {
      this.isShowHorizontalLayout = true

      if (!this.isShowNewFooterLayoutStyle) {
        return
      }

      this.$nextTick(()=>{
        // isShowHorizontalLayout 影响到 dom, setTimeout 在后续的宏任务中执行，可以确保 DOM 更新已完全完成
        setTimeout(() => {
          const rootFontSize = window.getComputedStyle(document.documentElement).fontSize.replace('px', '') // 根元素的px大小 
          const leftTotaTextlWidth =  this.orderConfirmDiscountSwitchByAbt ? 0 : (this.$refs.totalTextRef?.offsetWidth || 30) // 左侧total文案的宽度
          const totalContentMaxWidth = (166 / 37.5) * rootFontSize - leftTotaTextlWidth // 右侧价格+税费+箭头的最大宽度，设计稿的最大宽度170 间距4 170-4=166
          const originTotalPriceWidth = this.$refs.totalPriceRef?.offsetWidth || 0 // 原来价格的文案宽度
          const originTotalTaxWidth = this.$refs.totalTaxRef?.offsetWidth || 0 // 原来税费的文案宽度
          const otherWidth = 12 + ( 2 / 37.5) * rootFontSize // icon的宽度 + 左间距2
          const sumTotalWidth = originTotalPriceWidth + originTotalTaxWidth + otherWidth // 将价格、税费、icon横排起来，总共的宽度
  
          // 是否需要换行展示并缩小字号的逻辑是：将价格、税费、icon横排起来计算总共宽度，将其值与右侧文案最大值totalContentMaxWidth进行比较
          if (sumTotalWidth > totalContentMaxWidth) {
            this.isShowHorizontalLayout = false
          }
        }, 0)
      })
    },
    /**
     * @description: 处理首页底部左侧的内容透明度
     * @param {*} val 订单明细order total部分是否在可视区域
     */    
    handleFooterLeftContentStyle(val) {
      if (!this.isClient) {
        return
      }
      if (this.source == 'prePayModal') {
        this.$refs.summaryPriceWrapperRef?.classList?.remove('left-content-hidden')
        return
      }
      if (val) {
        this.$refs.summaryPriceWrapperRef?.classList?.remove('left-content-display')
        this.$refs.summaryPriceWrapperRef?.classList?.add('left-content-hidden')
        this.isOpacityChange = true
      } else if (this.isOpacityChange) {
        this.$refs.summaryPriceWrapperRef?.classList?.remove('left-content-hidden')
        this.$refs.summaryPriceWrapperRef?.classList?.add('left-content-display')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.summary-effiency-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 16/75rem 24/75rem;
  z-index: @zindex-hack;
  background: #fff;
  &__content {
    max-width: 340/75rem;
  }
  .summary-price-wrapper {
    line-height: 1;
    margin-right: 20/75rem;
    &__totalfee {
      margin-top: 4/75rem;
      .font-dpr(20px);
      color: #767676;
    }
    &__save-price {
      margin-top: 8/75rem;
      .font-dpr(24px);
      color: @sui_color_discount_dark;
    }
  }
  .tax-price {
    .font-dpr(20px);
    margin-top: 8/75rem;
    justify-content: flex-start;
    padding: 0;
    & > :last-child {
      margin-left: 4/75rem;
    }
  }
  .price-tax {
    .font-dpr(28px);
    &__total-text {
      margin-right: 8/75rem;
    }
    &__total-price {
      .font-dpr(34px);
      font-weight: bold;
    }
    &__color-discount {
      color: @sui_color_discount;
    }
    &__total-tax {
      .font-dpr(28px);
      font-weight: bold;
    }
    &__total-icon {
      margin-left: 4/75rem;
    }
  }
  .price-tax-new {
    display: flex;
    .font-dpr(28px);
    &__total-text {
      margin-right: 8/75rem;
    }
    &__total-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    &__horizontal-layout {
      white-space: nowrap;
    }
    &__scale-layout {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    &__total-right-content {
      display: flex;
      align-items: center;
    }
    &__total-price {
      .font-dpr(34px);
      font-weight: bold;
      width: 100%;
    }
    &__color-discount {
      color: @sui_color_discount;
    }
    &__total-tax {
      .font-dpr(28px);
      font-weight: bold;
    }
    &__total-icon {
      margin-left: 4/75rem;
    }
  }
}
.left-content-display {
  animation: leftContentDispaly 0.5s ease forwards;
}

@keyframes leftContentDispaly {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.left-content-hidden {
  animation: leftContentHidden 0.5s ease forwards;
}

@keyframes leftContentHidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
