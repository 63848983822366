<template>
  <div
    v-if="countryCode !== 'MY'" 
    class="normal-tax-item"
    :identification="identification"
  >
    <!-- 通用展示 -->
    <span
      v-if="safeguardTaxesConfig && safeguardTaxesConfig[taxInfo.taxType] && safeguardTaxesConfig[taxInfo.taxType].langKey"
      class="normal-tax-item__left"
    >
      <span>
        {{ language[safeguardTaxesConfig[taxInfo.taxType].langKey] }}
      </span>
      <Icon 
        v-if="taxTips && currentTaxConfig.showIcon"
        class="normal-tax-item__icon"
        name="sui_icon_doubt_16px_2" 
        size="16px" 
        color="#959595"
        @click="$emit('showTipsDialog', taxTips)"
      />
    </span>
    <span
      v-if="taxInfo.taxType==1"
      class="normal-tax-item__left"
    >
      <template v-if="isShowCaGst">
        {{ language.SHEIN_KEY_PWA_23975 }}
        <i
          class="suiiconfont sui_icon_doubt_16px_2 normal-tax-item__icon"
          @click.stop="handleClickShowGST"
        ></i>
      </template>
      <template v-else>
        {{ language.SHEIN_KEY_PWA_16154 }}
      </template>
    </span>
    <em>{{ taxInfo.taxPrice && taxInfo.taxPrice.amountWithSymbol }}</em>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { getTaxesConfig } from 'public/src/pages/common/helpers.js'

// 如果修改【taxTypes】的时候，需要更改组件PriceDetail2 的 priceDetailNameMap对象 的值
const taxTypes = [2, 3, 4, 5, 6, 7]

export default {
  components: {
    Icon
  },
  props: {
    taxInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({})
    },
    isShowCaGst: {
      type: Boolean,
      default: false
    },
    countryCode: {
      type: String,
      default: ''
    },
    identification: { // 标识，参与按照数值进行订单明细排序用到
      type: String,
      default: ''
    },
  },
  computed: {
    rewriteConfig () {
      if (this.countryCode === 'BR') {
        return {
          3: {
            _name: 'ICMS',
            langKey: 'SHEIN_KEY_PWA_25432'
          }
        }
      }
      if (this.countryCode === 'TR') {
        return {
          7: {
            _name: 'II',
            langKey: 'SHEIN_KEY_PWA_33619',
            hintKey: 'SHEIN_KEY_PWA_33620',
            showIcon: true
          }
        }
      }
      return {}
    },
    safeguardTaxesConfig () {
      return getTaxesConfig({ taxTypes, rewriteConfig: this.rewriteConfig })
    },
    currentTaxConfig() {
      return this.safeguardTaxesConfig[this.taxInfo.taxType]
    },
    taxTips() {
      const { hintKey } = this.currentTaxConfig
      return this.language[hintKey]
    }
  },
  methods: {
    handleClickShowGST () {
      this.$emit('clickShowGst')
    }
  }
}
</script>

<style lang="less" scoped>
.normal-tax-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16/75rem 0;
  line-height: 17/14;
  .font-dpr(28px);
  &__left {
    display: flex;
  }
  &__icon {
    color: @sui_color_gray_light1;
    margin-left: 8/75rem;
    line-height: 1;
  }
}
</style>
